import { template as template_163203d677e746d9b68310cc3d555008 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { NO_REMINDER_ICON, WITH_REMINDER_ICON } from "discourse/models/bookmark";
import icon from "discourse-common/helpers/d-icon";
import { i18n } from "discourse-i18n";
export default class BookmarkIcon extends Component {
    get icon() {
        if (this.args.bookmark?.get("reminder_at")) {
            return WITH_REMINDER_ICON;
        }
        return NO_REMINDER_ICON;
    }
    get cssClasses() {
        return this.args.bookmark ? "bookmark-icon bookmark-icon__bookmarked" : "bookmark-icon";
    }
    get title() {
        if (!this.args.bookmark) {
            return i18n("bookmarks.create");
        }
        return this.args.bookmark.get("reminderTitle");
    }
    static{
        template_163203d677e746d9b68310cc3d555008(`
    {{icon this.icon translatedTitle=this.title class=this.cssClasses}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
