import { template as template_65154a3ec3cd43fcac09f3a957a246aa } from "@ember/template-compiler";
const WelcomeHeader = template_65154a3ec3cd43fcac09f3a957a246aa(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
