import { template as template_d3ded03eed3d4accb2cb0c42dc632aec } from "@ember/template-compiler";
const FKLabel = template_d3ded03eed3d4accb2cb0c42dc632aec(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
